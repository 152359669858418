
import { computed, defineComponent, onMounted, ref } from 'vue'
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from 'vuex'
import CloudFun from '@cloudfun/core'
import { v1 as uuid } from 'uuid'

export default defineComponent({
  components: {
    // DarkModeSwitcher,
  },
  setup () {
    const captchaToken = ref(uuid())
    const captchaUrl = computed(() => `${process.env.VUE_APP_BACKEND_URL}/api/captcha?token=${captchaToken.value}`)
    // const adminTitle = computed(() => CloudFun.current?.model.state.configuration.value.AdminTitle || "貓公寓管理後臺")

    onMounted(async () => {
      cash('body')
        .removeClass('app')
        .removeClass('error-page')
        .addClass('login')
      cash('body')[0].click()
    })
    return {
      // adminTitle,
      account: '',
      password: '',
      captcha: '',
      captchaToken,
      captchaUrl,
      rememberMe: false
    }
  },
  methods: {
    ...mapActions(['login']),
    formSubmit () {
      this.login({ account: this.account, password: this.password, rememberMe: this.rememberMe, captcha: this.captcha, captchaToken: this.captchaToken }).then(
        () => this.$router.push('/'),
        failure => {
          this.$user = CloudFun.guest
          this.reloadCaptcha()
          CloudFun.send('warning', failure.message || '帳號或密碼錯誤')
        }
      )
    },
    reloadCaptcha () {
      this.captchaToken = uuid()
    }
  }
})
